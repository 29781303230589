<template>
  <!-- 诊所下拉列表 -->
  <el-select
    v-model="val"
    size="mini"
    @change="onChange"
    :filter-method="getList"
    @visible-change="getList"
    :disabled="disabled"
  >
    <el-option value="" label="全部" v-if="isAll"></el-option>
    <el-option
      v-for="item in list"
      :value="item.id"
      :label="item.name"
      :key="item.id"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    value: {
      type: [Number, String],
    },
    isAll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      val: "", //
      list: [],
      doctorid: 0,
      disabled: false,
    };
  },

  created() {
    this.getList("");
    if (localStorage.userData) {
      var userInfo = JSON.parse(localStorage.userData);
      this.doctorid = userInfo.id;
    }
    this.disabled = false;
  },
  methods: {
    onChange(val) {
      let list = this.list.filter((item) => item.id == this.val);
      this.$emit("input", val);
      this.$emit("change", val, list.length > 0 ? list[0] : null);
    },
    getList(keyword) {
      if (keyword === false) return;
      let params = {
        page_size: 10,
        current_page: 1,
        id: this.doctorid,
        keyword: keyword == true ? "" : keyword,
      };
      this.$axios.post("/ucenter/getbindcliniclist", params).then((res) => {
        if (res.code == 1) {
          this.list = res.data;
          let list = this.list.filter((item) => item.id == this.value);
          if (list.length > 0) {
            this.$emit("onInit", this.value, list[0]);
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  watch: {
    value(v, ov) {
      if (v != ov) {
        this.val = this.value;
        let list = this.list.filter((item) => item.id == this.value);
        if (list.length > 0) {
          this.$emit("onInit", this.value, list[0]);
        }
      }
    },
  },
};
</script>
